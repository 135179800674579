import Bindings from "../bindings";
import { ETransitionType } from "./nodes/transition";

export interface ICrop {
  top: number;
  left: number;
  right: number;
  bottom: number;
}

export interface IProperty {
  [propName: string]: {
    items: {
      name: string;
      value_string: string;
    }[];
  };
}

export type TSourceType =
  | "image_source"
  | "color_source"
  | "browser_source"
  | "slideshow"
  | "ffmpeg_source"
  | "text_gdiplus"
  | "text_gdiplus_v2"
  | "text_ft2_source"
  | "monitor_capture"
  | "window_capture"
  | "game_capture"
  | "dshow_input"
  | "wasapi_input_capture"
  | "wasapi_output_capture"
  | "decklink-input"
  | "scene"
  | "ndi_source"
  | "openvr_capture"
  | "screen_capture"
  | "liv_capture"
  | "ovrstream_dc_source"
  | "vlc_source"
  | "coreaudio_input_capture"
  | "coreaudio_output_capture"
  | "av_capture_input"
  | "display_capture"
  | "audio_line"
  | "syphon-input"
  | "soundtrack_source"
  | "mediasoupconnector"
  | "wasapi_process_output_capture"
  | "group";

export type TSourceSettings = { [key: string]: any };

export class Scene {
  constructor(public readonly name: string) {}

  static async all() {
    const sources = await Bindings.obs.enum_scenes();
    return sources.map((s) => new Scene(s.name));
  }

  static async getActive() {
    const { name } = await Bindings.obs.get_current_scene();
    return new Scene(name);
  }

  static async create(name: string) {
    await Bindings.obs.create_scene(name);
    sceneNames.push(name);

    return new this(name);
  }

  async addSource(source: Source) {
    await Bindings.obs.scene_add(this.name, source.name);

    return SceneItem.find(this.name, source.name);
  }

  async makeActive() {
    await Bindings.obs.set_current_scene(this.name);
  }

  async getSources({
    allSlabsSources,
  }: {
    allSlabsSources?: Awaited<
      ReturnType<typeof Bindings.obs.query_all_sources>
    >;
  } = {}) {
    const items = [];

    if (!allSlabsSources) {
      allSlabsSources = await Bindings.obs.query_all_sources();
    }

    const { source_names: sourceNames } = await Bindings.obs.scene_get_sources(
      this.name,
    );

    for (const sourceName of sourceNames) {
      const slabSource = allSlabsSources.find((s) => s.name === sourceName);

      if (!slabSource) {
        console.warn(`Could not find source ${sourceName}`);
        continue;
      }

      const sourceSettings =
        await Bindings.obs.source_get_settings_json(sourceName);
      items.push({
        source: slabSource,
        settings: sourceSettings,
      });
    }

    return items;
  }
}

export class Source {
  constructor(
    public readonly type: TSourceType,
    public readonly name: string,
  ) {}

  static async create(
    type: TSourceType,
    name: string,
    settings: TSourceSettings = {},
  ) {
    await Bindings.obs.source_create(type, name, JSON.stringify(settings));

    const source = new this(type, name);
    return source;
  }

  static async update(name: string, settings: TSourceSettings = {}) {
    return await Bindings.obs.source_set_settings_json(
      name,
      JSON.stringify(settings),
    );
  }

  async update(settings: TSourceSettings) {
    await Bindings.obs.source_set_settings_json(
      this.name,
      JSON.stringify(settings),
    );
  }

  static async all() {
    const sources = await Bindings.obs.query_all_sources();
    return sources.map((s) => new Source(s.id, s.name));
  }

  static async allWithSettings() {
    const sources = await Source.all();

    return Promise.all(
      sources.map((item) =>
        Bindings.obs
          .source_get_settings_json(item.name)
          .then((settings) => ({ ...item, settings })),
      ),
    );
  }

  static async findById(sourceId: string) {
    const sources = await Source.all();
    const source = sources.find((s) => s.name === sourceId);

    if (!source) {
      throw new Error(`Could not find source with id ${sourceId}`);
    }

    return source;
  }

  static async findByIdWithSettings(sourceId: string) {
    const source = await this.findById(sourceId);
    const settings = await Bindings.obs.source_get_settings_json(source.name);
    return { ...source, settings };
  }

  static async findByType(type: TSourceType) {
    // TODO: Update to OBS API
    const sources = await Bindings.obs.query_all_sources();
    return sources
      .filter((s) => s.id === type)
      .map((s) => new Source(s.id, s.name));
  }

  async getProperties() {
    return await Bindings.obs.source_get_properties_json(this.name);
  }

  async getSettings() {
    return await Bindings.obs.source_get_settings_json(this.name);
  }
}

export class Transition {
  constructor(
    public readonly type: ETransitionType,
    public readonly name: string,
  ) {}

  static async create(type: ETransitionType, name: string) {
    await Bindings.obs.add_transition(type, name);

    const transition = new this(type, name);
    return transition;
  }

  async update(settings: TSourceSettings) {
    await Bindings.obs.transition_set_settings_json(
      this.name,
      JSON.stringify(settings),
    );
  }

  async makeActive() {
    await Bindings.obs.set_current_transition(this.name);
  }
}

export class SceneItem {
  constructor(
    public readonly sceneName: string,
    public readonly sourceName: string,
  ) {}

  static find(sceneName: string, sourceName: string) {
    return new this(sceneName, sourceName);
  }

  async setPos(x: number, y: number) {
    await Bindings.obs.sceneitem_set_pos(this.sceneName, this.sourceName, x, y);
  }

  async setScale(x: number, y: number) {
    await Bindings.obs.sceneitem_set_scale(
      this.sceneName,
      this.sourceName,
      x,
      y,
    );
  }

  async setCrop(crop: ICrop) {
    await Bindings.obs.sceneitem_set_crop(
      this.sceneName,
      this.sourceName,
      crop.left,
      crop.top,
      crop.right,
      crop.bottom,
    );
  }
}

// TODO: This is a workaround for the fact that obs_query_all_sources does
// not return scenes, despite needing to check them for naming conflicts.
// Remove this once we have a C++ fix
let sceneNames: string[] = [];

export async function getUniqueSourceName(name: string) {
  const existingNames = (await Bindings.obs.query_all_sources())
    .map((s) => s.name)
    .concat(sceneNames);
  return suggestName(name, (testName) => existingNames.includes(testName));
}

export async function createUniqueSceneCollection(name: string) {
  sceneNames = [];

  const existingNames = (await Bindings.obs.get_scene_collections()).map(
    (col) => col.name,
  );

  const uniqueName = suggestName(name, (testName) => {
    return existingNames.includes(testName);
  });

  await Bindings.obs.add_scene_collection(uniqueName);
}

function suggestName(name: string, isTaken: (name: string) => boolean): string {
  if (isTaken(name)) {
    const match = name.match(/.*\(([0-9]+)\)$/);

    if (match) {
      const num = parseInt(match[1], 10);

      return suggestName(
        name.replace(/(.*\()([0-9]+)(\))$/, `$1${num + 1}$3`),
        isTaken,
      );
    }

    return suggestName(`${name} (1)`, isTaken);
  }

  return name;
}
